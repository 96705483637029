.writing {
  &--rtl {
    textarea {
      direction: rtl;
      unicode-bidi: bidi-override;
    }
  }

  &__prompt {
    color: #000000;
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 8px;
  }

  &__saving {
    color: #767676;
    font-size: 14px;
    font-style: italic;
    line-height: 24px;
    display: flex;
    align-items: center;
    padding-bottom: 8px;

    &__icon {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background-color: #1AAF5D;

      &__check {
        color: #FFFFFF;
        font-size: 12px;
        position: relative;
        bottom: 4px;
        left: 1px;
      }
    }
  }

  &__container {
    border: 1px solid #E6E8ED;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.1);
    border-radius: 4px;
    padding: 16px 16px 0 16px;

    &__text-area {
      border: 0;
      margin: 0;
      color: #585858;
      font-size: 14px;
      line-height: 24px;
      resize: none;
      padding-bottom: 16px;
    }
  }

  &__remember {
    padding-top: 20px;
    color: #767676;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
  }

  &__remember-point {
    color: #767676;
    font-size: 18px;
    font-weight: bold;
    line-height: 32px;
    display: flex;
    align-items: center;

    &__icon {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: #CDCDCD;

      &__check {
        color: #FFFFFF;
        font-size: 18px;
        position: relative;
        bottom: 4px;
        left: 1px;
      }
    }
  }
}
