
$avant-cdn-path: "https://cdn.avantassessment.com/";

$header-height: 70px;
$status-bar-height: 75px;

$item-padding: 25px;
$item-header-height: 50px;
$item-transition-dt: 0.3s; // Make sure this is the same as pageTransitionDt in ItemContainer

$max-content-width: 1280px;
$item-menu-width: 210px;
$item-panel-width: 480px;

$response-panel-width: 500px;
$situation-panel-width: 460px;

$item-wrapper-width: 1024px;

$footer-height: 100px;
$margin-xs-size: 8px;
$margin-sm-size: 10;
$margin-size: 20px;
$margin-xl-size: 50px;
$margin-xxl-size: 100px;

$avant-darker-blue: #011728;
$avant-dark-blue: #3B5470;
$avant-grey-blue: #738CA7;
$avant-blue: #4F6FD2;
$avant-light-blue: #E8F2F8;
$avant-light-grey: #BFCBCC;
$avant-dark-grey: #7F7F7F;
$avant-teal: #1ABC9C;
$avant-light-orange: #F5A623;
$avant-orange: #E67E22;
$avant-dark-orange: #D35400;
$avant-red: #E74C3C;
$avant-dark-teal: #16A085;
$avant-grey: lighten($avant-dark-grey, 7%);
$avant-teal-transparent: rgba(22, 160, 133, 0.1);

//Avant Style Colors as defined in inVision
$avant-style-blue: #2B5DD8;
$avant-style-black: #231F20;
$avant-style-yellow: #FFC900;
$avant-style-dark-grey: #767676;
$avant-style-grey: #CDCDCD;
$avant-style-red: #FF6F4C;
$avant-style-green: #1AAF5D;
$avant-style-white: #FFFFFF;

$avant-text-color: $avant-darker-blue;
$text-color: $avant-text-color;
$ps-selected-text-color: #FFC900;
$ps-clickable-text-color: #CDCDCD;

$font-size-base: 14px;
$item-font-size: 16px;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px
$font-size-h1: floor(($font-size-base * 2.6)); // ~36px
$font-size-h2: floor(($font-size-base * 2.15)); // ~30px
$font-size-h3: ceil(($font-size-base * 1.7)); // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5: $font-size-base;
$font-size-h6: ceil(($font-size-base * 0.85)); // ~12px

$alert-border-radius: 0px;
$state-warning-text: rgb(255, 255, 255);
$state-warning-bg: rgb(230, 126, 34);
$state-warning-border: rgb(230, 126, 34);
$brand-primary: $avant-dark-blue;
$brand-danger: $avant-red;
$brand-success: $avant-teal;

$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: darken($btn-primary-bg, 5%);

$btn-default-color: #fff;
$btn-default-bg: $avant-orange;
$btn-default-border: $avant-orange;

$btn-default-color: #fff;
$btn-default-bg: $avant-orange;
$btn-default-border: $avant-orange;

$btn-info-color: $avant-blue;
$btn-info-bg: #FFF;
$btn-info-border: $avant-light-grey;

$btn-info-color: $avant-blue;
$btn-info-bg: #FFF;
$btn-info-border: $avant-light-grey;

$btn-danger-color: #fff;
$btn-danger-bg: $brand-danger;
$btn-danger-border: darken($btn-danger-bg, 5%);

$btn-success-color: #fff;
$btn-success-bg: $brand-success;
$btn-success-border: darken($btn-success-bg, 5%);

$correct-answer-color: #00FF00;

$voicerecorder-btn-width: 108px;
$voicerecorder-stop-btn-width: 150px;
$voicerecorder-btn-height: 40px;
$voicerecorder-btn-border-radius: 4px;

$voicerecorder-btn-font-weight: bold;
$voicerecorder-btn-font-size: 16px;
$voicerecorder-btn-line-height: 19px;

$start-test-modal-border-color: rgba($black, .55);

$voicerecorder-txt-red: #D0021B;
$voicerecorder-bg-red: #D0021B;
$voicerecorder-bg-lightred: #F9E6E8;
$voicerecorder-tooltip-bg-grey: rgba(97, 97, 97, 0.9);
$voicerecorder-txt-white: #FFFFFF;
$voicerecorder-link-txt-lightblue: #519AC8;
$voicerecorder-header-txt-darkblue: #3B5470;
$voicerecorder-button-orange: #E67E22;
$voicerecorder-button-red: #D0021B;
$voicerecorder-bg-lightblue: #E8F2F8;
$voicerecorder-bg-grey: #C0C0C0;
$voicerecorder-bg-blue: #519AC8;
$voicerecorder-txt-blue: #519AC8;
$voicerecorder-addtorecording-icon: "#{$avant-cdn-path}resources/icons/icon-addtorecording.svg";
$voicerecorder-trash-icon: "#{$avant-cdn-path}resources/icons/icon-trash.svg";
$voicerecorder-stop-icon: "#{$avant-cdn-path}resources/icons/icon_stop.svg";
$voicerecorder-record-icon: "#{$avant-cdn-path}resources/icons/icon-record.svg";
$voicerecorder-play-icon: "#{$avant-cdn-path}resources/icons/icon-play.svg";
