@import "../../styles/variables.scss";

.login {
  background-color: #FFFFFF;
  padding-top: 150px;
  margin: 0 auto;

  &__image-container {
    margin-bottom: 24px;
    text-align: center;

    &__image {
      width: 156px;
      margin: auto;
    }

    &__text {
      color: #767676;
      font-family: Cera, sans-serif;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 30px;
      text-align: center;
      margin-top: 24px;
    }

    &--more-margin {
      margin-bottom: 48px;
    }
  }

  &__login-container {
    width: 460px;
    background-color: #FAFAFA;
    border: 1px solid rgba(205,205,205,0.31);
    border-radius: 4px;
    box-shadow: 1px 1px 5px 1px rgba(159,159,159,0.05);
    margin: 0;

    &--dark {
      background-color: #EDEDED;
    }

    &--padding {
      padding: 40px 50px 49px 50px;
    }

    .form-group {
      margin-bottom: 24px !important;
    }

    .form-control {
      height: 50px;
      width: 360px;
      border: 2px solid #CDCDCD;
      border-radius: 4px;
      background-color: #FFFFFF;
      margin-top: 12px !important;
    }

    &__form-error {
      .form-control {
        height: 50px;
        width: 360px;
        border: 2px solid rgba(249,61,92,0.56);
        border-radius: 4px;
        background-color: #FFFFFF;
        margin-top: 12px !important;
      }
    }

    &__error {
      margin-top: 12px;
      color: red;
    }

    &__top {
      margin-bottom: 32px;
      text-align: center;
      padding: 40px 50px 0 50px;

      &__title {
        color: #000000;
        font-size: 18px;
        font-weight: bold;
        line-height: 23px;
      }

      &__text {
        color: #585858;
        font-size: 14px;
        line-height: 24px;
        margin-top: 6px;
      }

      &__icon {
        color: #CDCDCD;
        margin-right: 8px;
      }

      &__link {
        &:hover {
          cursor: pointer;
        }
      }

      &__warning {
        border-radius: 6px;
        background-color: rgba(248, 28, 28, 0.16);
        padding: 16px 16px 20px 16px;
        margin-top: 8px;
        text-align: center;

        &__text {
          color: #585858;

          &--blue {
            color: #2B5DD8;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    &__form {
      padding: 0 50px 0 50px;
    }

    &__bottom {
      margin-top: 32px;
      text-align: center;
      padding: 0 50px 32px 50px;

      &--no-margin {
        margin-top: 0;
      }

      &--dark {
        background-color: #EDEDED;
        padding: 32px 50px 32px 50px;
      }

      &__text {
        color: #585858;
        font-size: 14px;
        line-height: 16px;

        &--margin {
          margin-bottom: 10px;
        }

        &--link {
          &:hover {
            cursor: pointer;
          }
        }

        &--blue {
          color: #2B5DD8;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &__button {
      height: 45px;
      width: 360px;
    }

    &__modal-button {
      height: 45px;
      width: 100%;
      display: block;
    }
  }

  &__bottom-container {
    margin-top: 16px;
    text-align: center;

    &__text {
      color: rgba(118, 118, 118, 0.5);
      font-size: 14px;
      line-height: 24px;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .Clever-classlink-button{
    display: flex;
    margin-left: -3px

  }
}
