.brand {

  &__logo {
    height: 45px;
    display: inline-block;
    vertical-align: baseline;
  }

  &__name {
    display: inline-block;
    color: #767676;
    font-family: Cera, sans-serif;
    font-size: 18px;
    line-height: 18px;
    margin-left: 8.32px;
  }
}
