@import "../../styles/variables.scss";

.item-ps {
  .ps-clickable-text {
    cursor: pointer;
    text-decoration: none;
    color: #585858;
    padding: 3px;
    background-color: $ps-clickable-text-color;

    &:hover {
      background-color: $ps-selected-text-color;
    }

    &.correct-answer {
      background-color: $correct-answer-color;
    }

    &.ps-selected {
      background-color: $ps-selected-text-color;
    }
  }
}
