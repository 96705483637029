@import "../../../styles/variables";

.DeleteRecordingIcon {
  display: inline-block;
  width: $voicerecorder-btn-height;
  height: $voicerecorder-btn-height;
  background-color: $voicerecorder-bg-lightred;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 4px;
  &__icon {
    position: relative;
    top: 9px;
    margin: 0 auto;
    width: 14px;
    height: 18px;
    background-image: url($voicerecorder-trash-icon);
  }
  &:hover {
    cursor: pointer;
    background-color: darken($voicerecorder-bg-lightred, 10%);
  }
}