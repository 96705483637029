@import "../../../styles/variables";

.Html5VoiceRecorder {
  width: 478px;

  &__controls {
    width: 100%;
    height: 109px;
    border: 1px solid #E8F2F8;
    border-radius: 4px;
    text-align: left;
    display: inline-block;
    

    &__button {
      width: 200px;
      display: inline-block;
      position: relative;
      top: -6px;
      left: 16px;
    }

    &__icons {
      width: 100px;
      position: relative;
      top: 8px;
      left: 12px;
      display: inline-block;
    }

    &__volume {
      width: 140px;
      display: inline-block;
      position: relative;
      left: 55px;
      top: 16px;

      &-hidden {
        visibility: hidden;
      }
    }

    &-processing {
      font-size: 30px;
      line-height: 2.7;
      color: $avant-dark-grey;
      margin-left: 20px;
    }

    &-confirmdelete {
      &__cancel {
        font-size: 1.2em;
        color: $voicerecorder-button-orange
      }
      &__confirm {
        font-size: 1.2em;
        color: white;
        background-color: $voicerecorder-button-orange;
      }
      &__message {
        &-header {
          font-size: 1.3em;
        }
        &-txt {

        }
      }
    }
  }

  &__helpMessage {

  }
}
