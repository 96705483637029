@import "../../styles/variables.scss";

.single-select {

  &__container {
    display: flex;
    border-color: #CDCDCD;
    background-color: #FAFAFA;
    color: #CDCDCD;

    &:hover {
      background-color: #CDCDCD;
      border-color: #CDCDCD;
      cursor: pointer;
    }

    &__choice {
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: inherit;
      background-color: inherit;
      color: #3B3832;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      font-family: "Helvetica Neue", sans-serif;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;

      &__text {
        // grouping block
      }

      &__check {
        // grouping block
      }

      &:hover {
        color: #3B3832;
      }

      &--checked {
        background-color: #CDCDCD;
        border-color: #CDCDCD;
      }

      &--audio {
        border-width: 1px 0 0 1px;
      }

      &--last {
        border-width: 1px 1px 1px 1px;
      }

      &--last-audio {
        border-width: 1px 0 1px 1px !important;
      }
    }

    &__audio-player {
      height: 50px;
      border-width: 1px 1px 0 0;
      border-style: solid;
      border-color: inherit;
      background-color: inherit;
      color: #CDCDCD;

      &__icon {
        font-size: 30px;
        margin-top: 5px;
      }

      &--checked {
        background-color: #CDCDCD;
        color: #ffffff;
      }

      &--last {
        border-width: 1px 1px 1px 0;
      }

      &:hover {
        background-color: #CDCDCD;
        border-color: #CDCDCD;
      }
    }

    &:hover .multi-select__container__audio-player__icon {
      color: #FFFFFF;
    }

    &__more {
      width: 34px;
      border-width: 1px 1px 0 0;
      border-style: solid;
      border-color: inherit;
      background-color: inherit;
      display: flex;
      align-items: center;
      justify-content: center;

      &__icon {
        font-size: 16px;
        line-height: 16px;
      }

      &--checked {
        background-color: #CDCDCD;
        color: #ffffff;
      }

      &--last {
        border-width: 1px 1px 1px 0;
      }
    }
  }

}
