@import "../../styles/variables.scss";

.tech-check {
  padding-top: $header-height;
  padding-bottom: 32px;
  background-color: rgba(205, 205, 205, 0.1);

  &__passed-banner {
    position: fixed;
    height: 50px;
    width: 100%;
    background-color: #1AAF5D;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #FFFFFF;
      margin-right: 8px;

      &__icon {
        color: #1AAF5D;
        font-size: 18px;
      }
    }
  }

  &__failed-banner {
    position: fixed;
    height: 50px;
    width: 100%;
    background-color: #F93D5C;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #FFFFFF;
      margin-right: 8px;

      &__icon {
        color: #F93D5C;
        font-size: 18px;
      }
    }
  }

  &__deprecated-banner {
    position: fixed;
    height: 50px;
    width: 100%;
    background-color: #FFC900;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #FFFFFF;
      margin-right: 8px;

      &__icon {
        color: #FFC900;
        font-size: 18px;
      }
    }
  }

  &__container {
    padding-top: 32px;
    display: flex;
    justify-content: center;

    &--with-banner {
      padding-top: 82px;
    }
  }

  &__not-checked-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    &__buttons {
      display: flex;
    }
  }

  &__voice-recorder-container {
    padding-left: 24px;
    padding-bottom: 24px;

    &--good {
      padding: 24px;
      background-color: #DAF2E5;
      display: flex;
      justify-content: space-between;
    }

    &--bad {
      padding: 24px;
      background-color: #FEDADA;
      display: flex;
      justify-content: space-between;
    }

    &--deprecated {
      padding: 24px;
      background-color: #FFE37F;
      display: flex;
      justify-content: space-between;
    }
  }

  &__voice-recorder-heading {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(216, 216, 216, 0.4);
    padding-left: 24px;
    padding-right: 20px;

    &:hover {
      cursor: pointer;
    }

    &__top {
      border-bottom: 1px solid #CDCDCD;
    }

    &__bottom {
      border-top: 1px solid #CDCDCD;

      &--open {
        border-bottom: 1px solid #CDCDCD;
      }
    }

    &__title-container {
      display: flex;
      align-items: center;

      &__icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #CDCDCD;

        &--good {
          background-color: #1AAF5D;
        }

        &--bad {
          background-color: #F93D5C;
        }

        &--deprecated {
          background-color: #FFC900;
        }

        &__icon {
          color: #FFFFFF;
          font-size: 20px;
        }
      }

      &__title {
        color: #585858;
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
        margin-left: 8px;
      }
    }

    &__expand-icon {
      color: #585858;
      font-size: 14px;
    }
  }

  &__voice-recorder-body {
    padding: 24px;
  }

  &__audio-player {
    height: 45px;
    width: 50.39px;
    border-radius: 6px;
    background-color: #2B5DD8;
    color: #FFFFFF;
  }

  &__retest-container {
    display: flex;
    align-items: center;
    color: #2B5DD8;

    &__icon {
      font-size: 16px;
      color: #2B5DD8;
      margin-right: 4px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__button {
    height: 35px;
    width: 111px;

    &--yes {
      margin-right: 8px;
    }
  }

  &__bold-text {
    color: #585858;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
  }

  &__text {
    color: #585858;
    font-size: 14px;
    line-height: 24px;
  }

  &__link {
    color: #2B5DD8;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;

    &:hover {
      cursor: pointer;
    }
  }

  &__text-area {
    height: 99px;
    width: 100%;
    border: 1px solid #E6E8ED;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.1);
    margin-top: 24px;
    margin-bottom: 16px;
    resize: none;
    padding: 16px;
  }

  &__content {
    width: 940px;
    background-color: #FFFFFF;

    &__title-container {
      height: 130px;
      background-color: #EFEFEF;
      text-align: center;
      padding: 32px;

      &__title {
        color: #000000;
        font-size: 28px;
        font-weight: bold;
        line-height: 34px;
        margin-bottom: 8px;
      }

      &__text {
        color: #585858;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
      }
    }

    &__body-container {
      padding: 24px 48px 48px 48px;

      &__top-text {
        color: #585858;
        font-size: 14px;
        line-height: 24px;
        text-align: center;

        &--link {
          color: #2B5DD8;

          &:hover {
            cursor: pointer;
          }
        }
      }

      &__table {
        min-width: 852px;
        margin-top: 40px;
        border-top: 1px solid #CDCDCD;
        border-left: 1px solid #CDCDCD;

        &__row {
          border-bottom: 1px solid #CDCDCD;
          display: flex;
        }

        &__first-col {
          border-right: 1px solid #CDCDCD;
          min-height: 80px;
          min-width: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &__second-col {
          border-right: 1px solid #CDCDCD;
          min-height: 80px;
          min-width: 175px;
          color: #000000;
          font-size: 18px;
          font-weight: bold;
          line-height: 24px;
          display: flex;
          align-items: center;
          padding-left: 32px;
        }

        &__third-col {
          border-right: 1px solid #CDCDCD;
          min-height: 80px;
          min-width: 597px;
          display: flex;
          align-items: center;
          padding: 0 24px;
          color: #585858;
          font-size: 14px;
          line-height: 24px;

          &--not-checked {
            padding: 24px;
          }

          &--voice-recorder {
            padding: 0;
          }
        }

        &__good-col {
          background-color: #DAF2E5;
          justify-content: space-between;
        }

        &__bad-col {
          background-color: #FEDADA;
          justify-content: space-between;
        }

        &__deprecated-col {
          background-color: #FFE37F;
        }

        &__icon {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #CDCDCD;

          &--good {
            background-color: #1AAF5D;
          }

          &--bad {
            background-color: #F93D5C;
          }

          &--deprecated {
            background-color: #FFC900;
          }

          &__icon {
            color: #FFFFFF;
            font-size: 32px;
          }
        }
      }
    }
  }
}
