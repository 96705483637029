@import "../../styles/variables.scss";

.status-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  color: #7E6300;
  background-color: #FFC900;
  height: $status-bar-height;
  width: 100%;
  display: flex;
  flex-direction: row;

  &--rtl {
    flex-direction: row-reverse;
  }

  &--centered {
    justify-content: space-between;
  }

  &__status-container {
    text-align: left;
    padding: 18px 46px 0 30px;

    &--rtl {
      text-align: right;
    }

    &--centered {
      text-align: center;
    }
  }

  &__section {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
  }

  &__status {
    color: rgba(0, 0, 0, 0.4);
    line-height: 16px;
    font-size: 14px;
  }

  &__progress {
    margin: 35px 0 !important;
    border-radius: 0 !important;
    height: 5px !important;
    color: rgba(0, 0, 0, 0.4) !important;
    background-color: #E6B500 !important;
    box-shadow: none !important;
    flex: 1 !important;

    .progress-bar {
      background-color: #FFFFFF !important;
    }

    &--rtl {
      flex-direction: row-reverse;

      .progress-bar {
        background-color: #FFFFFF !important;
        float: right;
      }
    }
  }

  &__button {
    background-color: #2B5DD8;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.69px;
    line-height: 19px;
    text-align: center;
    width: 150px;
    height: 75px;
    border: 0;

    &--next {
      margin-left: 16px;
    }

    &--rtl-next {
      margin-right: 16px;
    }

    &:disabled {
      background-color: #94ADEB;
      cursor: not-allowed;
    }

    &:focus {
      outline-width: 0px;
    }

  }
}
