@import "../../styles/variables.scss";

.header {
  position: fixed;
  width: 100%;
  height: $header-height;
  background-color: #FFFFFF;
  box-shadow: 0 1px 0 0 #CDCDCD;
  padding: 10px 32px 10px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
}
