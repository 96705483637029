@import "../../styles/variables.scss";

.response-panel {
  text-align: left;
  background-color: #FAFAFA;
  flex: 1;

  &__content-container {
    padding: 16px;
  }

  &__content {
    width: 412px;
    margin: 0 auto;

    @media screen and (min-width: 1280px) {
      width: 564px;
    }
  }

  &__zone-click-content {
    margin: 0 auto;
  }

  label {
    color: $avant-grey-blue;
  }

  .comment {
    border: 1px solid rgb(221, 221, 221);
    border-radius: 6px;
    padding: 1rem;

    p {
      margin: 0;
    }
  }
}
