@import "../../styles/variables.scss";

.advance-report {
  background-color: #FFFFFF;
  padding-top: $header-height + 37px;
  margin: 0 auto;


  &__top {

    width: 960px;
    display: flex;
    justify-content: space-between;
    margin: 0 42px;

    &__title {
      &__district {
        color: #000000;
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        margin-bottom: 8px;

      }

      &__dashboard {
        color: #000000;
        font-size: 28px;
        font-weight: bold;
        line-height: 34px;

      }
    }

    &__download-button {
      height: 50px;
      width: 221px;
    }

    &__dropdown {
      &__label {
        color: #231F20;
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
        margin-right: 16px;

        display: inline-block;
      }
    }
  }

  &__filters {
    width: 960px;
    margin: 32px 42px 16px 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__label {
      color: #231F20;
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
      margin-bottom: 8px;
    }

    &__input {
      height: 50px;
      width: 250px;
      border: 2px solid #CDCDCD;
      border-radius: 4px;
      background-color: #FFFFFF;
      padding-left: 16px;
      color: #767676;
      font-size: 16px;
      line-height: 18px;
    }
  }

  &__container {

    width: 960px;
    border: 2px solid #E7E7E7;
    border-radius: 4px;
    background-color: #FFFFFF;
    margin: 18px 42px 46px 42px !important;

    &__image {
      height: 16px;
      width: 16px;
      margin-left: 16px;
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;

    }

    &__icon {
      font-size: 24px;
      margin-left: 8px;
      margin-right: 8px;
      display: inline-block;
      vertical-align: middle;
    }

    &__arrow-up {
      color: #CDCDCD;
      font-size: 16px;
      margin-left: 14px;
      margin-right: 11px;
      display: inline-block;
      vertical-align: middle;

    }

    &__arrow-down {
      color: #CDCDCD;
      font-size: 16px;
      margin-left: 14px;
      margin-right: 11px;
      display: inline-block;
      vertical-align: middle;

      transform: rotate(90deg);
    }

    &__title {
      color: #585858;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      display: flex;
      justify-content: space-between;
      padding: 16px;

    }

    &__headings {
      height: 48px;
      background-color: #F2F2F2;
      color: #000000;
      font-weight: bold;

      padding-top: 16px;
      padding-bottom: 16px;
    }

    &__data-line {
      height: 1px;
      width: 918px;
      background-color: #CDCDCD;
      margin-left: 38px;
    }

    &__data {


      &--hover {
        &:hover {
          cursor: pointer;
        }
      }

      &--open {
        display: flex;
        flex-direction: column;
      }

      &--closed {
        display: none;
      }

      &--dark {
        background-color: #FAFAFA;
      }

      &__parent {
        color: #2B5DD8;
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;

        padding-top: 16px;
        padding-bottom: 16px;

        &__title {
          margin-left: 40px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &__child {
        color: #2B5DD8;
        font-size: 14px;
        line-height: 16px;

        padding-top: 16px;
        padding-bottom: 16px;

        &__title {
          margin-left: 50px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &__percent {
        color: #000000;
        font-size: 14px;
        line-height: 16px;

        padding-top: 16px;
        padding-bottom: 16px;

        &__lock--failed {
          display: inline-block;

          color: #CDCDCD;
          font-size: 24px;
          position: relative;
          right: 2px;
          bottom: 2px;
        }

        &__circle {
          &--unlocked {
            display: inline-block;

            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 2px solid #CDCDCD;
            background-color: #CDCDCD;
          }

          &--in-progress {
            display: inline-block;

            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 2px solid #2B5DD8;
            background-color: #2B5DD8;
          }

          &--failed {
            display: inline-block;

            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 3px solid #FF6F4C;
            background-color: #FF6F4C;

            .x-container {
              &__x {
                color: #FFFFFF;
                font-size: 20px;
                position: relative;
                bottom: 3px;
                right: 3px;

                &--alt {
                  right: 2.5px;
                }
              }
            }
          }

          &--completed {
            display: inline-block;

            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 2px solid #1AAF5D;
            background-color: #1AAF5D;

            .check-container {
              &__check {
                color: #FFFFFF;
                font-size: 20px;
                position: relative;
                bottom: 2px;
                right: 2.5px;
              }
            }
          }
        }
      }
    }

    &__border {
      border-bottom: 1px solid #CDCDCD;

    }

  }

}

.advance-report-educator {
  margin: auto;

  &__locked {
    color: #BFBFBF;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;

    &__icon {
      position: relative;
      bottom: 3px;
    }

    &__text {
      display: inline-block;
      margin-left: 8px;
      vertical-align: top;
    }
  }

  &__unlocked {
    color: #BFBFBF;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;

    &__text {
      display: inline-block;
      margin-left: 8px;
      position: relative;
      bottom: 5px;
    }
  }

  &__in-progress {
    color: #2B5DD8;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;

    &__text {
      display: inline-block;
      margin-left: 8px;
      position: relative;
      bottom: 5px;
    }
  }

  &__failed  {
    color: #FF6F4C;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;

    &__text {
      display: inline-block;
      margin-left: 8px;
      position: relative;
      bottom: 8px;
    }
  }

  &__completed {
    color: #36B574;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;

    &__text {
      display: inline-block;
      margin-left: 8px;
      position: relative;
      bottom: 7px;
    }
  }

  &__close-button {
    border: 0;
    background-color: transparent;
    padding-top: 17px;
    padding-right: 24.5px;
    float: right;

    &__icon {
      color: #000000;
      font-size: 30px;

      &:hover {
        color: rgba(0,0,0,0.5)
      }
    }
  }

  .modal-dialog {
    width: fit-content;
    height: fit-content;
    max-width: none;
  }

  .modal-body {
    padding: 0 40px 32px 40px;
  }

  &--margin {
    margin-right: 8px;
  }

  &__name {
    color: #000000;
    font-size: 28px;
    font-weight: bold;
    line-height: 34px;
    margin-bottom: 10px;
    margin-top: 47px;
  }

  &__username {
    color: #2B5DD8;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
  }

  &__language {
    margin-top: 29px;

    &__row {
      display: flex;
      justify-content: space-between;

      &--top {
        margin-bottom: 8px;
      }
    }

    &__info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      &__name  {
        color: #000000;
        font-size: 22px;
        font-weight: bold;
        line-height: 28px;
      }

      &__time {
        &__icon {
          height: 20px;
          width: 20px;
          display: inline-block;
        }

        &__text {
          color: #585858;
          font-size: 14px;
          line-height: 24px;
          display: inline-block;
        }
      }
    }

    &__module {
      height: 144px;
      width: 146px;
      border-radius: 0 0 4px 4px;
      display: inline-block;

      &__name {
        width: 146px;
        background-color: #231F20;
        text-align: center;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
        margin: auto;
        padding: 8px;
      }

      &__info {
        height: 111px;
        width: 146px;
        border-radius: 0 0 4px 4px;
        background-color: #F2F2F2;
        margin: auto;
        padding-top: 5px;

        &--time-only {
          padding-top: 32px;
        }

        &__status {
          height: 36px;
          width: 130px;
          border-radius: 20px;
          background-color: #FFFFFF;
          text-align: center;
          margin: 0 auto 10px auto;
          padding: 8px;
        }

        &__completed {
          text-align: center;
          color: #585858;
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 6px;
        }

        &__time {
          text-align: center;
          margin: 0 auto;

          &__icon {
            height: 20px;
            width: 20px;
            margin-right: 13px;
            display: inline-block;
          }

          &__text {
            color: #585858;
            font-size: 14px;
            line-height: 24px;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }
}
