@import "../../styles/variables.scss";

.avant-button {

  &--primary {
    background-color: #2B5DD8;
    border: 0;
    border-radius: 6px;
    color: #FFFFFF;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 17px;
    text-align: center;

    &:focus {
      outline-width: 0px;
    }

    &:hover {
      background-color: #6085E1;
    }

    &:active {
      background-color: #2045A1;
    }

    &:disabled {
      cursor: not-allowed;
      &:hover {
        background-color: #94ADEB;
      }
    }
  }

  &--secondary {
    border: 2px solid #2B5DD8;
    border-radius: 6px;
    font-family: "Helvetica Neue", sans-serif;
    color: #2B5DD8;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 17px;
    text-align: center;

    &:focus {
      outline-width: 0px;
    }

    &:hover {
      border: 2px solid #6085E1;
      color: #6085E1;
    }

    &:active {
      border: 2px solid #2045A1;
      color: #2045A1;
    }
  }

  &--white {
    border-radius: 6px;
    background-color: #FFFFFF;
    border: 0;

    color: #767676;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 17px;
    text-align: center;

    &:hover {
      background-color: #E2E2E2;
    }

    &:active {
      background-color: #CDCDCD;
    }

    &:focus {
      outline-width: 0px;
    }
  }

  &--option {
    border: 2px solid #CDCDCD;
    border-radius: 4px;
    background-color: #FFFFFF;

    &:focus {
      outline-width: 0px;
    }
  }

  &--audio-player {
    height: 45px;
    width: 50.39px;
    border-radius: 6px;
    background-color: #2B5DD8;
    margin-bottom: 30px;
    color: #FFFFFF;

    &__icon {
      font-size: 28px;
      margin-top: 5px;
    }

    &:focus {
      outline-width: 0px;
    }

    &:hover {
      background-color: #6085E1;
    }

    &:active {
      background-color: #2045A1;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &--link {
    color: #2B5DD8;
    font-size: 14px;
    line-height: 24px;
    border: 0;
    background-color: transparent;

    text-align: center;
    font-family: "Helvetica Neue", sans-serif;

    &--dashboard {
      width: 80px;
      margin-right: 56px;
    }

    &:hover {
      color: #6085E1;
    }

    &:active {
      color: #2045A1;
    }

    &:focus {
      outline-width: 0px;
    }
  }

  &--default {
    height: 35px;
    width: 120px;

    &:focus {
      outline-width: 0px;
    }
  }

  &--logout {
    height: 35px;

    &:focus {
      outline-width: 0px;
    }
  }

  &--bold {
    font-weight: bold;
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--link-blue {
    color: #0B4060;
    background: transparent;
    border: transparent;

    &:active,
    &:focus,
    &:hover {
      color: darken(#0B4060, 10%);
    }
  }

  &--link-black {
    color: #000000;
    background: transparent;
    border: transparent;

    &:active,
    &:focus,
    &:hover {
      color: #000000;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &--link-gray {
    color: #767676;
    background: transparent;
    border: transparent;

    &:active,
    &:focus,
    &:hover {
      color: darken(#767676, 10%);
    }
  }

  &--link-orange {
    color: $avant-orange;
    background: transparent;
    border: transparent;

    &:active,
    &:focus,
    &:hover {
      color: darken($avant-orange, 10%);
    }
  }

  &--white-on-blue {
    color: #FFFFFF;
    background-color: $avant-blue;

    &:hover:not(.avant-button--disabled) {
      background-color: darken($avant-blue, 10%);
    }
  }

  &--white-on-orange {
    color: #FFFFFF;
    background-color: $avant-orange;

    &--border {
      border-color: darken($avant-orange, 15%);
    }

    &:hover:not(.avant-button--disabled) {
      background-color: darken($avant-orange, 10%);
    }
  }

  &--blue-on-white {
    color: $avant-blue;
    background-color: #FFF;
    border-color: $avant-light-grey;


    &:hover:not(.avant-button--disabled) {
      background-color: darken(#FFF, 10%);
    }
  }

  &--blue-on-gray {
    color: #0B4060;
    background-color: #E7E7E7;

    &:hover {
      background-color: darken(#E7E7E7, 10%);
    }
  }

  &--white-on-gray {
    color: #FFFFFF;
    background-color: #CDCDCD;

    &:hover {
      background-color: darken(#CDCDCD, 10%);
    }
  }

  &--white-on-red {
    color: #FFFFFF;
    background-color: $brand-danger;

    &:hover {
      background-color: darken($brand-danger, 10%);
    }
  }

  &--white-on-green {
    color: #FFFFFF;
    background-color: $brand-success;

    &:hover {
      background-color: darken($brand-success, 10%);
    }
  }

  &--yes-no-white-on-green {
    height: 45px;
    width: 140px;
    border-radius: 4px;
    background-color: $avant-style-green;
    color: $avant-style-white;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      background-color: lighten($avant-style-green, 10%);
    }

    &:focus {
      outline-width: 0px;
    }

    &--answered {
      background-color: darken($avant-style-green, 10%);
      border: 3px solid $avant-style-yellow;

      &:hover {
        background-color: darken($avant-style-green, 10%);
      }
    }
  }

  &--yes-no-white-on-red {
    height: 45px;
    width: 140px;
    border-radius: 4px;
    background-color: $avant-style-red;
    color: $avant-style-white;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      background-color: lighten($avant-style-red, 10%);
    }

    &:focus {
      outline-width: 0px;
    }

    &--answered {
      background-color: darken($avant-style-red, 10%);
      border: 3px solid $avant-style-yellow;
      &:hover {
        background-color: darken($avant-style-red, 10%);
      }
    }

  }

  &--submit {
    color: #FFFFFF;
    background-color: #4F6FD2;
    width: 270.65px;
    height: 50px;

    &:hover {
      background-color: darken(#4F6FD2, 10%);
    }
    &:focus {
      outline-width: 0px;
    }
  }

  &--large-text {
    font-size: $font-size-h2;
  }

  &--small {
    margin: 5px 5px 0px 0px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }

  &--advance {
    margin: 0px 5px 0px 5px;
  }

  &--xsmall {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }

  &--disabled {
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed !important;
    background-color: #E2E2E2;
    border: 0;

    &:hover {
      background-color: #E2E2E2;
    }
  }
}
