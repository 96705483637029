.start-test-modal {
  .modal-content {
    box-shadow: 0 3px 10px 0 rgba(92,92,92,0.71);
    border-radius: 0;
    background-clip: border-box;
    border: $modal-content-border-width solid $start-test-modal-border-color;
    width: 460px;
    margin: auto;

    .modal-header {
      background-color: #FAFAFA;
      padding: 0 0 20px 0;

      .modal-title {
        color: #585858;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        text-align: center;
      }
    }

    .modal-body {
      padding: 16px 16px 40px 40px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__image {
      margin-right: 32px;

      img {
        height: 112px;
      }

      &--4se {
        img {
          height: 141.8px;
        }
      }
    }

    &__text {
      color: #585858;
      font-size: 14px;
      line-height: 24px;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;

      &__button {
        height: 45px;
        width: 116px;
      }
    }
  }
}
