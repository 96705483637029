.profile-progress {

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 21px;

  &__circle {
    &--inactive {
      display: inline-block;

      height: 26px;
      width: 26px;
      border-radius: 50%;
      border: 3px solid rgba(0,0,0,0.2);
      background-color: #FAFAFA;
    }

    &--active {
      display: inline-block;

      height: 26px;
      width: 26px;
      border-radius: 50%;
      border: 3px solid #1AAF5D;
    }

    &--visited {
      display: inline-block;

      height: 26px;
      width: 26px;
      border-radius: 50%;
      border: 3px solid #1AAF5D;
      background-color: #1AAF5D;

      .check-container {
        &__check {
          color: #FFFFFF;
          font-size: 23px;
          position: relative;
          bottom: 2px;
          right: 1px;
        }
      }
    }
  }

  &__bar {
    &--inactive {
      display: inline-block;
      vertical-align: text-top;

      height: 3px;
      width: 120px;
      background-color: rgba(0,0,0,0.2);
    }

    &--active {
      display: inline-block;
      vertical-align: text-top;

      height: 3px;
      width: 120px;
      background-color: #1AAF5D;
    }
  }
}