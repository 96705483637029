@import "../../styles/variables.scss";

.password-checker {
  width: 300px;
  border-radius: 4px;
  background-color: #FAFAFA;
  text-align: left;
  margin-bottom: 16px;

  &__good-text {
    color: #767676;
    font-size: 14px;
    line-height: 24px;
    display: inline-block;
    vertical-align: middle;
  }

  &__good-circle {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: #1AAF5D;
    display: inline-block;
    margin-right: 18px;
    vertical-align: middle;
  }

  &__bad-text {
    color: #CDCDCD;
    font-size: 14px;
    line-height: 24px;
    display: inline-block;
    vertical-align: middle;
  }

  &__bad-circle {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: #CDCDCD;
    display: inline-block;
    margin-right: 18px;
    vertical-align: middle;
  }

  &__bad-circle-error {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: #ff0000;
    display: inline-block;
    margin-right: 18px;
    vertical-align: middle;
  }

}
