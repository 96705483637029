@import "../../../styles/variables";

$volumebar-segment-red: #D0021B;
$volumebar-segment-lightred: #F9E6E8;
$volumebar-segment-blue: #519AC8;
$volumebar-segment-lightblue: #E8F2F8;
$volumebar-segment-yellow: #F8E71C;
$volumebar-segment-grey: #EDEDEE;
$volumebar-segment-green: #36B574;
.VolumeSegment {
  width: 24px;
  height: 7px;
  border-radius: 2px;
  margin-bottom: 2px;
  &--color {
    &-red {
      background-color: $volumebar-segment-red;
    }

    &-lightred {
      background-color: $volumebar-segment-lightred;
    }
    &-blue {
      background-color: $volumebar-segment-blue;
    }
    &-lightblue {
      background-color: $volumebar-segment-lightblue;
    }
    &-yellow {
      background-color: $volumebar-segment-yellow;
    }
    &-green {
      background-color: $volumebar-segment-green;
    }
    &-grey {
      background-color: $volumebar-segment-grey;
    }
  }

  &--nested {
    position: absolute;
  }
}
