.selector {
  position: relative;
  display: inline-block;

  &:hover {
    cursor: pointer;
  }

  &__button {
    height: 50px;
    width: 221px;
    border: 2px solid #CDCDCD;
    background-color: #FFFFFF;
    color: #767676;
    font-size: 16px;
    line-height: 18px;
    padding-top: 12px;
    padding-left: 8px;

    &--closed {
      border-radius: 4px;
    }

    &--open {
      border-radius: 4px 4px 0 0;
    }

    &__icon {
      font-size: 20px;
      color: #CDCDCD;
      position: relative;
      left: 180px;
    }
  }

  &__selected-option {
    display: inline-block;
    width: 168px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }


  &__options {
    position: absolute;
    z-index: 1;
    width: 221px;
    border-color: #CDCDCD;
    border-style: solid;
    border-width: 0 2px 2px 2px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);

    &--open {
      display: inherit;
    }

    &--closed {
      display: none;
    }

    &__option {
      height: 48px;
      background-color: #FFFFFF;
      padding: 15px 14px;
      color: #3B3832;
      font-size: 16px;
      line-height: 18px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &:hover, &:active {
        background-color: #CDCDCD;
      }
    }
  }
}
