@import "../../styles/variables.scss";

.picture-click {
  &__prompt {
    color: #585858;
    font-size: 14px;
    line-height: 24px;
  }

  &__images {
    display: flex;
    flex-direction: column;

    &__row {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;

      @media screen and (min-width: 1280px) {
        margin-bottom: 56px;
      }
    }

    &__column {
      position: relative;

      &--first-column {
        margin-right: 20px;

        @media screen and (min-width: 1280px) {
          margin-right: 56px;
        }
      }

      &__image {
        height: 220px;
        width: 220px;
        box-shadow: 0 3px 10px 0 rgba(92,92,92,0.15);
        border: solid 6px #FFFFFF;

        img {
          height: 220px;
          width: 220px;
          max-height: 220px;
          max-width: 220px;
        }

        &.correct-answer {
          border: solid 6px $correct-answer-color;
        }

        &--selected {
          border: solid 6px #CDCDCD !important;
        }

        &:hover {
          cursor: pointer;
          box-shadow: 0 6px 13px 6px rgba(0,0,0,0.21);
        }
      }

      &__icon {
        position: absolute !important;
        top: 16px !important;
        right: 16px !important;
        z-index: 1;
        color: #CDCDCD;
        height: 34px;
        width: 34px;
        border-radius: 50%;
        background-color: #CDCDCD;

        &__check {
          color: #FFFFFF;
          font-size: 30px;
          position: relative;
          top: 2px;
          left: 5px;
        }
      }
    }
  }
}
