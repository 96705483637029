@import "../../../../styles/variables";

.InputDeviceSelection {

  font-size: 14px;
  font-weight: bold;
  margin-bottom: 26px;
  text-align: left;
  &--title {
    margin-bottom: 2px;
  }
  &__list {
    border: 2px solid #EDEDEE;
    background-color: white;

    &__device {
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.InputDeviceSelection select {
  height: 40px;
  padding-left: 12px;
  width: 412px;

  -webkit-appearance: none;
  -moz-appearance: none;

  // Sexy Arrow Override: https://codepen.io/vkjgr/pen/VYMeXp
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
  linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
  calc(100% - 15px) calc(1em + 2px),
  calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px,
  5px 5px,
  1px 1.5em;
  background-repeat: no-repeat;
}

