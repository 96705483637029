@import "../../styles/variables.scss";

.dashboard {
  padding-top: $header-height + 32px;
  flex: 1;

  &__title {
    color: #000000;
    font-size: 28px;
    font-weight: bold;
    line-height: 34px;
    text-align: center;
  }

  &__subtitle {
    color: #585858;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }

  &__date {
    color: #585858;
    font-size: 14px;
    font-style: italic;
    line-height: 24px;
    text-align: center;
  }

  &__button {
    height: 45px;
    width: 220px;
    margin-top: 40px;
  }

  &__not-you {
    color: #000000;
    font-size: 14px;
    line-height: 16px;

    &__link {
      color: #2B5DD8;
      font-size: 14px;
      line-height: 16px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__click-animal {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    margin-top: 24px;
  }

  &__started-skill {
    height: 188px;
    width: 446px;
    background-color: rgba(205, 205, 205, 0.1);
    padding: 24px;
    text-align: left;
    margin: 40px auto auto auto;

    &__content {
      display: flex;
      justify-content: normal;

      &__image {
        height: 140px;
        width: 140px;
        margin-bottom: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 24px;

        img {
          height: 121.41px
        }

        &__4se {
          background-color: #FFFFFF;
          box-shadow: 0 3px 10px 0 rgba(92, 92, 92, 0.15);

          img {
            height: 95.85px
          }
        }
      }


      &__title {
        color: #000000;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 18px;
      }

      &__progress {
        display: flex;
        width: 140px;

        &__text {
          color: #767676;
          font-size: 12px;
          line-height: 16px;
          width: 140px !important;
        }

        &__bar {
          height: 14px;
          border-radius: 0;
          color: rgba(0,0,0,0.4);
          background-color: #D8D8D8;
          box-shadow: none !important;
          flex: 1;

          .progress-bar {
            background-color: #E6B500;
            box-shadow: none !important;
          }
        }
      }

      &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;

        &__button {
          height: 45px;
          width: 116px;
        }
      }
    }
  }

  &__skills {
    margin: 32px auto auto auto;
    display: inline-flex;
    justify-content: space-between;

    &__skill-container {
      position: relative;
      margin: 0 10px 0 10px;

      &:hover {
        cursor: pointer;
      }

      &--handwritten {
        &:hover {
          cursor: not-allowed;
        }
      }

      &__skill {
        height: 140px;
        width: 140px;
        margin-bottom: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 121.41px;
        }

        &__handwritten {
          color: #585858;
          font-size: 14px;
          font-weight: bold;
          line-height: 18px;
          text-align: center;
          padding: 0 16px;
        }

        &--4se {
          height: 140px;
          width: 140px;

          img {
            height: 140px;
            width: 140px;
            padding: 10px;
            background-color: #FFFFFF;
            box-shadow: 0 3px 10px 0 rgba(92,92,92,0.15);
          }

        }
      }

      &__completed-skill {
        height: 140px;
        width: 140px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          cursor: not-allowed;
        }

        &--4se {
          img {
            background-color: rgba(205, 205, 205, 0.15);
          }
        }

        &__check {
          width: 53px;
          opacity: 1.0 !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &__circle {
          height: 68.49px;
          width: 68.49px;
          background-color: #F2F2F2;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          &__4se_check {
            color: #FFFFFF;
            font-size: 50px;
            font-weight: bold;
          }


        }
      }

      &__completed-progress {
        text-align: center;

        &__progress {
          height: 4px;
          width: 140px;
          background-color: #1AAF5D;
          opacity: 0.25;
          margin-bottom: 8px;
        }

        &__text {
          color: #585858;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          font-weight: 600;
          width: 140px;

          &__opaque {
            opacity: 0.25;
            color: #767676;
            font-weight: 400;
          }
        }


      }

      &__title {
        color: #000000;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        text-align: center;
      }

      &--disabled {

        h4 {
          opacity: 0.25;
        }

        img {
          opacity: 0.25;
        }

        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}

