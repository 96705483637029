@import "../../../../styles/variables";

.StartListeningButton {
  width: $voicerecorder-btn-width;
  height: $voicerecorder-btn-height;
  border-radius: $voicerecorder-btn-border-radius;
  font-weight: $voicerecorder-btn-font-weight;
  font-size: $voicerecorder-btn-font-size;
  line-height: $voicerecorder-btn-line-height;
  background-color: $voicerecorder-bg-lightblue;
  color: $voicerecorder-txt-blue;
  border: 0px;
  &__icon {
    display: inline-block;
    height: 16px;
    width: 16px !important;
    top: 3px;
    left: -26px;
    background: url($voicerecorder-play-icon) round;
  }

  &__text {
    position: relative;
    top: -2px;
    left: 8px;
  }

  &:hover {
    cursor: pointer;

    background-color: darken($voicerecorder-bg-lightblue, 10%);
  }
}
