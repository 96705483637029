.yes-no-prompt {
  font-size: larger;
  padding-left: 6px;
  padding-bottom: 12px;
}

.prompt-content {

}

.mc-prompt {
  font-size: 16px;
  padding-bottom: 10px;
}

.cb-prompt {
  font-size: 16px;
  padding-bottom: 10px;
}

.prompt-substring {
  color: #585858;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  padding-bottom: 10px;
}
