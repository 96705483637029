@import "../../../styles/variables";

.DeleteConfirmationOverlay {
  position: relative;
  display: flex;
  width: 101%;
  height: 100%;
  border-radius: 4px;

  &__background {
    width: 100%;
    height: 100%;
    opacity: 0.95;
    background-color: white;
  }

  &__text {
    position: relative;
    left: 10px;
    display: inline-block;
    margin-right: 8px;
    &__header {
      font-size: 16px;
      font-weight: bold;
    }
    &__message {
      font-size: 15px;
}
    &__messagebox {
      font-size: 15px;
      margin-top: -6px;
    }
    &__box{
      text-align: end;
      margin-top: 24px;
      margin-left: 10px;
    }

  }

  &__cancel {
    position: relative;
    display: inline-block;
    font-size: 15px;
    color: $voicerecorder-button-red;
    background: none;
    margin-right: 6;
    border: none;
    &:hover {
      cursor: pointer;
      color: darken($voicerecorder-button-red, 10%)
    }
  }

  &__confirm {
    height: 30px;
    width: 136px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    border: none;
    border-radius: 4px;
    font-size: 13px;
    display: inline-block;
    background-color: $voicerecorder-button-red;
    color: white;
    &:hover {
      cursor: pointer;
      background-color: darken($voicerecorder-button-red, 10%);
    }
  }
}