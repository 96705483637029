@import "../../styles/variables.scss";

.ps-target-text {
  color: #585858;
  text-align: center;
  vertical-align: bottom;
  padding: 3px;
  background-color: $ps-clickable-text-color;

  &--selected {
    background-color: $ps-selected-text-color;
  }
}
