.instruction-panel {
  background-color: #FFFFFF;
  flex: 1;

  &--item-menu {
    margin-left: 224px;
  }

  &__content {
    background-color: #FFFFFF;
    width: 376px;
    margin: 0 auto;
    padding: 16px;

    @media screen and (min-width: 1280px) {
      width: 564px;
    }

    h1, h2, h3, h4, h5 {
      margin-top: 0;
      color: #000000;
      font-size: 22px;
      font-weight: bold;
      line-height: 30px;
      text-align: right;
    }
  }

  .advance-prompt {
    display: flex;
  }

  .advance-response {
    border: 1px solid rgb(221, 221, 221);
    border-radius: 6px;
    padding: 1rem;
    margin-top: 0.5rem;
    display: flex;
  }

  .plainimage-wrapper {
    text-align: center !important;
    padding: 16px;
    display: flex;
    justify-content: center;
  }

  &__small-image-container {
    background-color: #FFFFFF;
    width: 376px;
    margin: 0 auto;

    @media screen and (min-width: 1280px) {
      width: 564px;
    }
  }

  .small-image {
    float: left;
    width: 30%;
    margin-top: 24px;
    margin-right: 16px;
    margin-left: 16px;

    img {
      width: 100%;
    }
  }
}
