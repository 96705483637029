@import "../../../styles/variables";

.AddToRecordingIcon {
  display: inline-flex; 
  align-items: center;  
  background-color: $voicerecorder-bg-lightblue;
  font-weight: $voicerecorder-btn-font-weight;
  padding: 7px 16px;       
  color: $voicerecorder-txt-blue;
  border-radius: 4px;

  &__icon {
    
    width: 18px;     
    height: 26px;          
    margin-right: 16px;
  }
  &__text {
    margin-left: -9px;
  }

  &:hover {
    cursor: pointer;
    background-color: darken($voicerecorder-bg-lightblue, 10%);
  }
}
.tooltip-text {
  font-family: Arial;
  font-size: 13px
}
.AddToRecordingIconGrey {
  display: inline-flex; 
  font-weight: $voicerecorder-btn-font-weight;
  align-items: center;  
  background-color: $voicerecorder-bg-grey;
  padding: 7px 16px;      
  margin-left: -25px;
  margin-right: 24px;
  color: $voicerecorder-tooltip-bg-grey;
  border-radius: 4px;

  &__icon {
    width: 18px; 
    height: 26px;          
    margin-right: 16px; 
    color: grey;
  }

  &__tooltip {
    font-size: 0.9em,
  }

  &__text {
    font-size: 1em;     
  }
}
