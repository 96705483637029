@import "../../../styles/variables";

.MessageDropDown {
  width: 100%;

  display: block;
  position: relative;
  top: 24px;
  padding-left: 32px;
  padding-top: 16px;
  background-color: white;
  margin-bottom: 12px;
  border: 1px solid #eee;
  border-radius: 4px;
  text-align: left;
  &__title {
    font-size: 21px;
    color: $voicerecorder-header-txt-darkblue;
    font-weight: bold;
    padding-bottom: 8px;
  }

  &__message {
    font-size: 14px;
    font-weight: normal;
    color: black;
    padding-bottom: 8px;
  }

  &__help {
    padding-bottom: 12px;
    font-size: 14px !important;
    font-weight: normal !important;
    color: $voicerecorder-txt-blue;
    &:hover {
      cursor: pointer;
      color: darken($voicerecorder-txt-blue, 10%);
    }
  }
}