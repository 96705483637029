.item-video-container {

  .vjs-control-bar {
    display: none;
  }

  .vjs-big-play-button {
    border-radius: 30px;
  }
}
