.confirmation-modal {
  .modal-content {
    box-shadow: 0 3px 10px 0 rgba(92,92,92,0.71);
    border-radius: 0;
    width: 460px;
    margin: auto;

    .modal-header {
      background-color: #FAFAFA;
      padding: 0 0 20px 0;

      .modal-title {
        color: #585858;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        text-align: center;
      }
    }

    .modal-body {
      padding: 16px 40px 40px 40px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &__text {
      color: #585858;
      font-size: 14px;
      line-height: 24px;
    }

    &__buttons {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 16px 22.5px 0 22.5px;

      &__button {
        height: 45px;
        width: 116px;
      }
    }
  }
}

.avant-modal-button {
  &--primary {
    background-color: #2B5DD8;
    border: 0;
    border-radius: 6px;
    color: #FFFFFF;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 17px;
    text-align: center;

    &:hover {
      background-color: #6085E1;
    }

    &:active {
      background-color: #2045A1;
    }
  }
}
