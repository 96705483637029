@import "../../styles/variables.scss";

.advance {
  margin-top: $header-height + 32px;
  margin-bottom: 32px;

  hr {
    border-top: 2px solid $avant-light-grey;
  }

  .card {
    min-height: 200px;
    height: 296px;

    .status {
      color: #1AAF5D;
      display: block;
      margin: 5px 0;
    }

    .disabled {
      color: $avant-light-grey;
    }

    .btn-default[disabled] {
      background-color: $avant-light-grey;
      border-color: $avant-light-grey;
    }

    .padded {
      margin: 0 20%;
    }
  }

  &__dashboard-title {
    color: #000000;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
  }

  &__module-title {
    color: #000000;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    margin-bottom: 8px;
  }

  &__button {
    height: 45px;
  }
}

.practice-test {
  margin-top: $header-height + 32px;

  h1 {
    color: #000000;
    font-weight: lighter;
    text-align: center;
    margin-bottom: 5rem;
  }

  label {
    color: #000000;
    font-weight: bold;
    cursor: pointer;
  }

  .list-group-item:focus {
    background: white;
  }

  .practice-test-button-container {
    text-align: center;

    .practice-test-button {
      padding: 1rem 8rem;
      text-transform: uppercase;
      font-weight: bolder;
      letter-spacing: 1px;
    }
  }
}

.finish-module-container {
  margin-top: $header-height + 32px;
  margin-bottom: 5rem;

  h1 {
    color: $avant-dark-blue;
    font-weight: lighter;
    text-align: center;
    margin-bottom: 4rem;
  }

  .finish-module-button-container {
    text-align: center;
    margin-bottom: 6rem;

    .finish-module-button {
      padding: 1rem 6rem;
      text-transform: uppercase;
      font-weight: bolder;
      letter-spacing: 1px;
    }
  }

  .finish-module-table-panel {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .finish-module-table {
      color: $avant-dark-blue;
      border: 1px solid $avant-dark-grey;
      margin: auto;

      td, th {
        padding: 1rem 2rem;
      }

      .finish-module-table-left-col {
        background: $avant-light-blue;
        text-align: right;
      }
    }
  }

  .incorrect-answers-container {
    .incorrect-answer {
      color: $avant-dark-blue;
      text-align: left;
      padding: 2rem;

      .incorrect-answer-element {
        padding-bottom: 2rem;
      }

      .incorrect-answer-label {
        color: #000000;
        font-weight: bolder;
      }
    }
  }

  &__button {
    height: 45px;
    width: 360px;
  }
}

.finish-section-container {
  margin-top: $header-height + 32px;
  text-align: center;
}
