@import "../../../styles/variables.scss";

.advance-eula {
  color: #000000;

  &__container {
    padding: 32px;
    font-family: "Helvetica Neue", sans-serif;
    width: 1024px;
    margin: $header-height + 32px auto 0 auto;

    .card-body {
      padding: 0;
    }

    &__title {
      color: #000000;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      padding-bottom: 16px;
      margin: 0;
      text-align: center;
    }

    &__subtitle {
      color: #585858;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 32px;
    }

    &__summary {
      color: #404040;
      font-size: 14px;
      line-height: 16px;
    }

    &__list {
      margin: 0;
    }

    &__summary-title {
      color: #000000;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      margin-top: 32px;
      margin-bottom: 16px;
    }

    &__summary-text {
      padding-bottom: 16px;
      margin: 0;
    }

    &__instruction {
      padding-bottom: 16px;
      margin: 24px 0 0 0;
    }

    &__body {
      overflow-y: scroll;
      height: 176px;
      border: 1px solid #CCCCCC;
      border-radius: 4px;
      background-color: rgba(242,242,242,0.3);
      box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
      padding: 16px 32px 16px 16px;
    }

    &__body-title {
      text-align: center;
      color: #404040;
      font-size: 14px;
      line-height: 16px;
    }

    &__body-text {
      color: #404040;
      font-size: 14px;
      line-height: 16px;
    }

    &__agree-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 24px;
    }

    &__disagree-button {
      text-align: center;
      padding-top: 16px;
    }

    &__button {
      padding: 14px 72px;
    }
  }
}
