@import "../../styles/variables.scss";

.profile {
  background-color: #FFFFFF;
  margin: 0 auto;
  padding-top: $header-height + 32px;


  &__profile-container {
    width: 940px;
    height: 695px;
    border: 2px solid #E7E7E7;

    &__top {
      padding: 42px;
      height: 144px;
      border-bottom: 2px solid #E7E7E7;
      border-radius: 4px 4px 0 0;
      background-color: #FAFAFA;


      &__title {
        color: #231F20;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        text-align: center;
      }
    }

    &__bottom {
      height: 551px;
      width: 940px;
      padding: 40px;

      &__title {
        color: #000000;
        font-size: 18px;
        font-weight: bold;
        line-height: 23px;
        text-align: left;
        margin-bottom: 6px;
      }

      &__subtitle {
        color: #585858;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        margin-top: 8px;
      }

      &__text {
        color: #585858;
        font-size: 14px;
        line-height: 16px;
        text-align: left;

        &--italic {
          font-style: italic;
        }
      }
    }

    &__warning {
      width: 535px;
      border-radius: 6px;
      background-color: rgba(248, 28, 28, 0.16);
      padding: 16px 16px 20px 16px;
      margin: 8px auto 0 auto;
      text-align: left;
      display: block;
      overflow: auto;

      &__text {
        color: #585858;

        &--blue {
          color: #2B5DD8;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &__form {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      .form-group {
        text-align: left;
        margin-top: 24px !important;
      }

      .form-control {
        height: 50px;
        width: 100%;
        border: 2px solid #CDCDCD;
        border-radius: 4px;
        background-color: #FFFFFF;
        margin-top: 12px !important;
      }

      &--error {
        &__message {
          color: #F93D5C;
          font-size: 12px;
          font-style: italic;
          line-height: 14px;
          margin-top: 8px;

          &:before {
            font-family: "Material Icons";
            content: "\e88e";
            color: #F93D5C;
            font-style: normal;
            vertical-align: bottom;
            margin-right: 3px;
          }
        }

        .form-control {
          height: 50px;
          width: 100%;
          border: 2px solid rgba(249,61,92,0.56);
          border-radius: 4px;
          background-color: #FFFFFF;
          margin-top: 12px !important;

        }

        .wide-form {
          .form-control {
            height: 50px;
            width: 620px !important;
            border: 2px solid rgba(249,61,92,0.56);
            border-radius: 4px;
            background-color: #FFFFFF;
            margin-top: 12px !important;

          }
        }
      }

      &__button {
        margin-top: 32px;
        height: 45px;
        width: 300px;
        border-radius: 6px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 17px;
        text-align: center;
        color: #FFFFFF;
        background-color: #2B5DD8;

        &__arrow {
          color: #FFFFFF;
          font-size: 20px;
          line-height: 20px;
          display: inline-block;
          vertical-align: middle;
          padding-left: 14px;
        }

        &:focus {
          outline-width: 0px;
        }
      }
    }
  }
}
