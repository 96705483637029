.instruction-audio {
  &__text {
    color: #585858;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
  }

  &__rtl {
    direction: rtl;
    text-align: right;
  }
}
