//$print-page-size: auto;

@import '~bootstrap/scss/bootstrap.scss';
@page { size: auto }
@import "variables.scss";
@import "../components/Button/Button.scss";
@import "../components/StatusBar/StatusBar.scss";
@import "../components/Header/Header.scss";
@import "../components/Brand/Brand.scss";
@import "../components/Writing/Writing.scss";
@import "../components/AudioPlayer/AudioPlayer.scss";
@import "../components/ResponsePanel/ResponsePanel.scss";
@import "../components/ResponsePrompt/ResponsePrompt.scss";
@import "../components/InstructionPanel/InstructionPanel.scss";
@import "../components/InstructionAudio/InstructionAudio.scss";
@import "../components/InstructionVideo/InstructionVideo.scss";
@import "../components/InstructionImage/InstructionImage.scss";
@import "../components/InstructionText/InstructionText.scss";
@import "../components/SingleSelectGroup/SingleSelectGroup.scss";
@import "../components/MultiSelectGroup/MultiSelectGroup.scss";
@import "../components/PassageSelect/PassageSelect.scss";
@import "../components/ZoneClick/ZoneClick.scss";
@import "../components/Speaking/Speaking.scss";
@import "../components/PictureClick/PictureClick.scss";
@import "../components/YesNo/YesNo.scss";
@import "../components/FillInTheBlank/FillInTheBlank.scss";
@import "../components/InstructionPassageSelect/InstructionPassageSelect.scss";
@import "../components/voice-recording/Html5VoiceRecorder/Html5VoiceRecorder.scss";
@import "../components/voice-recording/VoiceVolumeVisualization/VoiceVolumeVisualization.scss";
@import "../components/voice-recording/VolumeSegment/VolumeSegment.scss";
@import "../components/voice-recording/AddToRecordingIcon/AddToRecordingIcon.scss";
@import "../components/voice-recording/DeleteRecordingIcon/DeleteRecordingIcon.scss";
@import "../components/voice-recording/VoiceRecorderButton/StartListeningButton/StartListeningButton.scss";
@import "../components/voice-recording/VoiceRecorderButton/StopListeningButton/StopListeningButton.scss";
@import "../components/voice-recording/DeleteConfirmationOverlay/DeleteConfirmationOverlay.scss";
@import "../components/voice-recording/MessageDropDown/MessageDropDown.scss";
@import "../components/voice-recording/MessageDropDown/InputDeviceSelection/InputDeviceSelection.scss";
@import "../components/Login/Login.scss";
@import "../components/advance/advance-eula/AdvanceEula.scss";
@import "../components/Profile/Profile.scss";
@import "../components/advance/register-user/RegisterUser.scss";
@import "../components/admin-portal/AdminPortal.scss";
@import "../components/ProfileProgress/ProfileProgress.scss";
@import "../components/AdvanceReport/AdvanceReport.scss";
@import "../components/advance/Advance.scss";
@import "../components/Dropdown/Dropdown.scss";
@import "../components/ItemMenu/ItemMenu.scss";
@import "../components/AudioCheck/AudioCheck.scss";
@import "../components/PasswordChecker/PasswordChecker.scss";
@import "../components/SpecialCharacterKeyboard/SpecialCharacterKeyboard.scss";
@import "../components/Dashboard/Dashboard.scss";
@import "../components/StartTestModal/StartTestModal.scss";
@import "../components/TechCheck/TechCheck.scss";
@import "../components/ConfirmationModal/ConfirmationModal.scss";
@import "../components/ItemVideo/ItemVideo.scss";

* {
  box-sizing: border-box;
}

/********************************
Avant Bootstrap Overwrites
*********************************/
.avant-orange {
  color: $avant-orange !important;
}

.avant-blue {
  color: $avant-blue !important;
}

.avant-grey {
  color: $avant-grey !important;
}

.avant-dark-grey, .secondary {
  color: $avant-dark-grey !important;
}

.avant-light-grey {
  color: $avant-light-grey !important;
}

.avant-grey-blue {
  color: $avant-grey-blue !important;
}

.secondary-text {
  @extend .secondary;
  font-style: italic;
}

.btn {
  font-size: 1.75rem;
}

.btn-secondary {
  background-color: $avant-orange;
  border-color: $avant-orange;
}
/********************************
End Avant Boostrap Overwrites
*********************************/

/****************************
General and Surgical classes
****************************/
.center-text {
  text-align: center;
}

.no-margin {
  margin: 0;
}

.margin-xs-top {
  margin-top: $margin-xs-size;
}

.margin-xs-bottom {
  margin-bottom: $margin-xs-size;
}

.margin-top {
  margin-top: $margin-size;
}

.margin-bottom {
  margin-bottom: $margin-size;
}

.margin-xl-top {
  margin-top: $margin-xl-size;
}

.margin-xl-bottom {
  margin-bottom: $margin-xl-size;
}

.margin-xxl-top {
  margin-top: $margin-xxl-size;
}

.margin-xxl-bottom {
  margin-bottom: $margin-xxl-size;
}

.margin-xl-left {
  margin-left: $margin-xl-size;
}

.margin-xl-right {
  margin-right: $margin-xl-size;
}

.margin-left {
  margin-left: $margin-size
}

.margin-right {
  margin-right: $margin-size
}

.no-padding {
  padding: 0
}

.padding-xs {
  padding: $margin-xs-size
}

.padding-xs-top {
  padding-top: $margin-xs-size;
}

.padding-xs-bottom {
  padding-bottom: $margin-xs-size;
}

.padding {
  padding: $margin-size
}

.padding-top {
  padding-top: $margin-size;
}

.padding-bottom {
  padding-bottom: $margin-size;
}

.padding-xl {
  padding: $margin-xl-size
}

.padding-xl-top {
  padding-top: $margin-xl-size;
}

.padding-xl-bottom {
  padding-bottom: $margin-xl-size;
}

.padding-xxl-top {
  padding-top: $margin-xxl-size;
}

.padding-xxl-bottom {
  padding-bottom: $margin-xxl-size;
}

.padding-xl-left {
  padding-left: $margin-xl-size;
}

.padding-xl-right {
  padding-right: $margin-xl-size;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clear-fix {
  clear: both;
}

.padding-for-footer {
  padding-bottom: $footer-height + $margin-xl-size;
}

.success-text {
  color: $brand-success;
}

.danger-text {
  color: $brand-danger;
}

.error {
  @extend .danger-text;
  font-size: 0.9em;
}

audio::-webkit-media-controls-enclosure {
  overflow: hidden;
}

audio::-webkit-media-controls-panel {
  width: calc(100% + 30px);
}

/****************************
End General and Surgical classes
****************************/
html {
  height: 100%;
  font-size: 8px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", sans-serif;
  background-color: #FFFFFF;
  height: 100%;
  min-height: 100% !important;
  font-size: 1.75rem;
}

@media print {
  body {
    -webkit-print-color-adjust: exact; 
  }
}

.routes {
  flex: 1;
  display: flex;
  flex-direction: column;
}

h1 {
  color: rgb(62, 87, 115);
  font-weight: lighter;
  margin: 30px 0 30px 0;
  font-size: $font-size-h1;
}

.avant-orange {
  color: $avant-orange;
}

h2 {
  color: rgb(62, 87, 115);
  font-weight: lighter;
  margin: 30px 0 30px 0;
  font-size: $font-size-h2;
}

h3 {
  font-weight: bold;
  color: $avant-orange;
  margin-bottom: 30px;
  font-size: $font-size-h3;
}

p.large {
  font-size: $font-size-large;
  color: darken($avant-dark-grey, 10%);
  line-height: 1.5em;
}

.debug {
  display: none;
  font-size: 0.9em;
  background: #ccc;
}

.required:after {
  content: "*";
  color: #FF0000;
}

:focus {
  outline: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

:active, :focus, :hover {
  outline: 0 !important;
}

.not-visible {
  visibility: hidden;
}

dl {
  width: 100%;
  margin-bottom: 0;

  dt {
    width: 50%;
    @extend label;
    padding-right: 5px;
    text-align: right;
    float: left;
    clear: left;
  }
  dd {
    color: $avant-blue;
    font-size: 1.1em;
    font-weight: bold;
    display: inline-block;
    float: left;
  }
}

.auto-saving {
  color: rgba(255, 255, 255, 0.5);
}

.edit-profile-icon {
  font-size: 0.85em;
}

/********************************
End General and Surgical classes
*********************************/

/************************************
ITEM STYLES
*************************************/
.item-container {
  position: unset !important;
  margin: $header-height 0 $status-bar-height 0;
  width: 100%;
  padding: 0;
  flex: 1;
  display: flex;
  font-size: 16px;
  line-height: 26px;

  div, span {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .item-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    background-color: #FFF;
    vertical-align: top;

    .video-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item {
      overflow-x: hidden;
      flex: 1;
      display: flex;
      margin: 0 !important;
      background-color: #FAFAFA;
    }

    .item-content {
      display: flex;
      flex: 1;
      min-block-size: fit-content;

      textarea {
        width: 100%;
      }

      &.rtl {
        flex-direction: row-reverse;
        textarea {
          direction: rtl;
          unicode-bidi: bidi-override;
        }
      }

      .drop-zone {
        text-align: start;
        padding-bottom: 1rem;

        .drop-zone-text {
          padding: 0.5rem;
        }

        .drop-zone-content {
          width: 100%;
          text-align: center;

          img {
            width: 75px;
          }

        }
      }

      .picture-click-images {
        width: 480px !important;
      }

      .picture-click-image {
        border: solid 0.5rem white;
        width: 200px !important;

        &.correct-answer {
          border: solid 0.5rem $correct-answer-color;
        }

        &.selected {
          border: solid 0.5rem $avant-dark-teal;
          width: 200px !important;
        }

      }

      .picture-click-image:hover {
        cursor: pointer;
      }

      .selected-icon {
        position: absolute;
        top: 10px;
        right: 20px;
        color: $avant-dark-teal;
        z-index: 1;
        font-size: 2rem;
      }
    }

    &.transition-out-fade {
      opacity: 0;
      transition: opacity $item-transition-dt linear;
    }

    &.transition-out-ltr {
      .item-content {
        left: $item-wrapper-width;
        position: relative;

        transition: left $item-transition-dt ease-in;
        -moz-transition: left $item-transition-dt ease-in;
        -webkit-transition: left $item-transition-dt ease-in;
      }
    }

    &.transition-out-rtl {
      .item-content {
        left: -$item-wrapper-width;
        position: relative;

        transition: left $item-transition-dt ease-in;
        -moz-transition: left $item-transition-dt ease-in;
        -webkit-transition: left $item-transition-dt ease-in;
      }
    }
  }

  .item-header {
    padding: 15px $item-padding 15px $item-padding;
    color: #FFF;
    background-color: $avant-blue;
    height: $item-header-height;
  }

  .item-footer {
    text-align: center;
    padding: 10px 10px 10px 10px;
  }

  .audio-choice {
    display: inline-flex;
    align-items: center;
  }

  .direction-rtl {
    text-align: right;
    direction: rtl;
    unicode-bidi: embed;
    //font-face: Traditional Arabic;
    font-family: "Geeza Pro";
  }
}

.item-instructions {
  &__blank-item {
    padding-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15%;
    margin-right: 15%;
  }

  .direction-rtl {
    text-align: right;
    direction: rtl;
    unicode-bidi: embed;
  }
}

/************************************
END ITEM STYLES
*************************************/

/*********************************
HEADER AND FOOTER STYLES
*********************************/

.header-wrapper {
  background-color: #FFF;
  margin-bottom: 46px;
  font-size: 1.75rem;

  .header {
    //margin: auto;
    //max-width: $max-content-width;
    color: $avant-dark-blue;
    padding: 10px 30px 10px 30px;

    img {
      vertical-align: bottom;
    }

    .brand {
      display: inline;

      .name {
        padding-left: 10px;
        font-size: 1.5em;
        color: $avant-orange;
        font-weight: bold;
      }
    }

    .links {
      display: inline;
      float: right;

      .navbar-dashboard-link {
        text-transform: uppercase;
        color: $avant-dark-blue;
        background: transparent;
        border: 0;
        font-weight: bold;
        font-size: 1.1em;
        letter-spacing: 1px;
      }

      .navbar-dashboard-link:active, .navbar-dashboard-link:focus, .navbar-dashboard-link:hover {
        color: $avant-blue;
        background: transparent;
        border: 0;
        font-weight: bold;
        font-size: 1.1em;
        letter-spacing: 1px;
      }

      .navbar-button {
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 1.1em;
      }

      .navbar-primary-button {
        @extend .navbar-button;
        border: 1px solid darken($brand-primary, 50%);
      }

      .navbar-logout-link {
        @extend .navbar-button;
        border: 1px solid $avant-dark-orange;
      }

    }

  }
}

.logo-image {
  height: 58px;
}

.footer {
  img {
    height: 40px;
    vertical-align: bottom;
  }
  span {
    color: $avant-grey-blue;
    font-style: normal;
  }
  background-color: rgb(255, 255, 255);
  height: $footer-height;
  padding-top: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: black;
  text-align: center;
}

// This came from Siri so might not be approproate for other apps
.header {
  .brand {
    .name {
      font-size: 1.8em !important;
      margin-right: 20px;
    }
    .logo-image {
      height: 32px;
      vertical-align: top;
    }

  }
}

/*********************************
END HEADER AND FOOTER STYLES
*********************************/

/*********************************
PANEL STYLES
*********************************/
.card {
  box-shadow: 0px 2px 4px $avant-style-dark-grey;
  padding: 20px 40px 20px 40px;
  margin-bottom: 20px;

  .card-top {
    border-bottom: 2px solid $avant-light-blue;
    margin-bottom: 10px;

    h1, h2 {
      color: $avant-orange;
      text-align: center;
      margin: 10px 0 10px 0;
    }

    p {
      color: $avant-dark-grey;
      text-align: center;
    }
  }
}

/*********************************
PANEL STYLES
*********************************/

/*********************************
FORM STYLES
*********************************/

label {
  color: #000000;
  display: inline;
  font-weight: 700;
  font-size: 1.75rem;
}

form {
  label {
    color: #000000;
    cursor: pointer;
  }
}

.form-control {
  font-size: 1.75rem;
}

/*********************************
END FORM STYLES
*********************************/

/********************************
STATUS BAR
********************************/

.faux-link {
  cursor: pointer;

  &:hover {
    color: $avant-light-blue;
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

//.status-bar-wrapper {
//position: fixed;
//bottom: 0;
//left: 0;
//  background-color: $avant-dark-blue !important;
//  width: 100%;
//  .status-bar {
//    margin: auto;
//    color: rgb(255, 255, 255);
//    max-width: $max-content-width;
//    padding: 10px 0 10px 0;
//    .status-bar-buttons {
//      margin-right: 10px;
//    }
//  }
//}

.low-time {
  color: red;
  font-weight: bold;
}

/********************************
END STATUS BAR
********************************/

/********************************
PAGE/VIEW SPECIFIC STYLES
*********************************/

.content-container {
  max-width: 1060px;
  margin: auto;

  > .row {
    margin-left: 0;
    margin-right: 0;
    background-color: #FFF;

    &.content-warning {
      background-color: $avant-dark-blue !important;
      color: rgb(255, 255, 255);
      width: 100%;
      .status-bar-buttons {
        margin-right: 10px;
      }
      padding: 10px 10px 10px 10px;
      font-weight: lighter;
      font-size: 1.2em;
    }
  }

  a {
    color: $avant-blue;
    font-weight: bold;
  }

  //.content-panel {
  //  border-left: 2px solid $avant-light-grey;
  //}
}

.profile.content-container {
  p {
    margin-bottom: 0;
  }
}

.profile-view {
  @extend .margin-xl-bottom;
  dl {
    font-size: $font-size-h4;
    dt {
      color: inherit;
      font-weight: normal;
    }
    dd {
      color: $avant-orange;
      font-weight: normal;
      max-width: 317px;
      text-align: left;
    }
  }
}

/********************************
END PAGE/VIEW SPECIFIC STYLES
*********************************/

/*****************************
DASHBOARD STYLES
******************************/

//.dashboard {
//
//  h1 {
//    color: $avant-dark-blue;
//    font-weight: lighter;
//  }
//
//  hr {
//    border-top: 2px solid $avant-light-grey;
//  }
//  .panel {
//    min-height: 200px;
//
//    &.apt-test-panel {
//      height: 160px;
//    }
//
//    &.apt-test-complete-panel {
//      height: 260px;
//    }
//
//    .status {
//      color: #1AAF5D;
//      display: block;
//      margin: 5px 0;
//    }
//
//    .disabled {
//      color: $avant-light-grey;
//    }
//
//    .btn-default[disabled] {
//      background-color: $avant-light-grey;
//      border-color: $avant-light-grey;
//    }
//
//    .padded {
//      margin: 0 20%;
//    }
//  }
//
//  &.apt {
//
//    .dashboard-button {
//      width: 20rem;
//    }
//
//    .top-dashboard-button {
//      margin-bottom: 2rem;
//    }
//
//    .bottom-dashboard-button {
//      background: $avant-dark-blue;
//      border-color: $avant-dark-blue;
//    }
//
//    .bottom-dashboard-button:hover {
//      background: $avant-darker-blue;
//      border-color: $avant-darker-blue;
//    }
//
//    .larger-text {
//      color: rgb(62, 87, 115);
//      font-weight: lighter;
//      font-size: $font-size-h2;
//    }
//  }
//
//}

/*****************************
END DASHBOARD STYLES
******************************/

/********************************
AUDIO CHECK STYLES
********************************/

.audio-check-wrapper {
  padding: 2rem;
}

.audio-check-confirm-text {
  font-weight: bold;
}

.audio-check-sample-player {
  width: 500px;
  margin: 0 auto;
}

.audio-check-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

/********************************
END AUDIO CHECK STYLES
********************************/

/********************************
CIRCLES
********************************/

$no-color: #cdcdcd;
$yes-color: #cdcdcd;
$correct-color: #1abc9c;
$incorrect-color: rgb(231, 76, 60);
$partial-color: rgb(230, 126, 34);
$circle-sm-diameter: 15px;
$circle-xs-diameter: 12px;

.no {
  color: $no-color;
}

.partial {
  color: $partial-color;
}

.yes {
  color: $yes-color;
}

.incorrect {
  color: $no-color;
}

.correct {
  color: #1abc9c;
}

.circle-sm, .circle-xs {
  display: inline-block;
  border-radius: 50%;
  width: $circle-sm-diameter;
  height: $circle-sm-diameter;

  &.no {
    background-color: $no-color;
    border: 1px solid $no-color;
    &.interactive {
      &:hover {
        background-color: lighten($no-color, 20%);
        cursor: hand;
        cursor: pointer;
      }
    }
  }

  &.incorrect {
    background-color: $incorrect-color;
    border: 1px solid $incorrect-color;
    &.interactive {
      &:hover {
        background-color: lighten($no-color, 20%);
        cursor: hand;
        cursor: pointer;
      }
    }
  }

  &.partial {
    background-color: $partial-color;
    border: 1px solid $partial-color;
    &.interactive {
      &:hover {
        background-color: lighten($partial-color, 20%);
        cursor: hand;
        cursor: pointer;
      }
    }
  }

  &.yes {
    background-color: $yes-color;
    border: 1px solid $yes-color;
    &.interactive {
      &:hover {
        background-color: lighten($yes-color, 20%);
        cursor: hand;
        cursor: pointer;
      }
    }
  }

  &.correct {
    background-color: $correct-color;
    border: 1px solid $correct-color;
    &.interactive {
      &:hover {
        background-color: lighten($yes-color, 20%);
        cursor: hand;
        cursor: pointer;
      }
    }
  }
}

.circle-xs {
  width: $circle-xs-diameter;
  height: $circle-xs-diameter;
}

/********************************
END CIRCLES
********************************/

/************************************
LOADER STYLES
*************************************/

.loader-pane {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -60px;
  margin-left: -60px;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(115, 140, 167, 0.2);
  border-right: 1.1em solid rgba(115, 140, 167, 0.2);
  border-bottom: 1.1em solid rgba(115, 140, 167, 0.2);
  border-left: 1.1em solid $avant-dark-blue;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/************************************
END LOADER STYLES
*************************************/

/*****************************
PAGE NOT FOUND STYLES
******************************/

.page-not-found-content {
  display: flex;
  justify-content: center;
  align-items: center;

  .page-not-found-message {
    width: 55rem;

    .warning-icon {
      font-size: 8em;
      color: $avant-red;
      float: right;
      padding-left: 2rem;
    }
  }
}

/*****************************
END PAGE NOT FOUND STYLES
******************************/

/*****************************
  ERROR ALERT STYLES
******************************/

.alerts {
  z-index: 10001;
  position: fixed;
  top: 1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

}

/*****************************
END ERROR ALERT STYLES
******************************/

/********************************
SUMMARY TABLE
********************************/
.summary-table-wrapper {
  overflow-y: auto;
  .summary-table {
    th {
      background-color: $avant-light-blue;
      min-width: 120px;
    }
  }
}

/********************************
SUMMARY TABLE
********************************/

/********************************
DISCLAIMER STYLES
********************************/

.disclaimer-container {
  text-align: center;
  padding-bottom: 100px;
}

.disclaimer-wrapper {
  width: 1000px;
  margin: auto;
  margin-top: 1em;
}

.disclaimer-wrapper {
  width: 49.5%;
  min-height: 400px;
  .left {
    float: left;
  }

  .right {
    float: right;
    padding-left: 1em;
  }
}

/********************************
END DISCLAIMER STYLES
********************************/

/********************************
DATE PICKER STYLES
********************************/

.react-datepicker__input-container {
  input {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  }
}

.react-datepicker__current-month {
  font-size: 12px;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.5rem;
  line-height: 2.5rem;
  font-size: 12px;
}
/********************************
END DATE PICKER STYLES
********************************/

/********************************
LICENSE KEY STYLES
********************************/

.license-keys {
  text-align: center;
}

.create-user {
  text-align: end;
}

.more-button-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
}

.license-filters {
  margin-bottom: 1rem;
}

.list-group-no-bottom-margin {
  margin-bottom: 0 !important;
  padding-left: 0;
}

.license-key-claimed {
  background-color: $avant-teal-transparent;
}

.license-key-claimed-key {
  margin-right: 1rem;
  background-color: $avant-teal-transparent;
  height: 2rem;
  width: 2rem;
  border: 1px solid $avant-dark-grey;
  border-radius: 2px;
}

.license-key-not-claimed-key {
  margin-right: 1rem;
  background-color: white;
  height: 2rem;
  width: 2rem;
  border: 1px solid $avant-dark-grey;
  border-radius: 2px;
}

.license-key-claim-key-container {
  display: flex;
}

/********************************
END LICENSE KEY STYLES
********************************/

/********************************
KEYBOARD STYLES
********************************/

.ui-keyboard {
  position: absolute;
}

.ui-keyboard-keyset {
  background: rgba(#000, 0.8);
  text-align: center;
}

.ui-keyboard-button {
  font-size: 1em;
  margin: 0.2em;
  border: none;
  border-radius: 3px;
  color: #FFF;
  background: rgba(#FFF, 0.1);
  padding: 0.7em 1.3em;
  cursor: pointer;
  &:hover {
    background: rgba(#FFF, 0.4);
  }
}

.ui-keyboard-space {
  width: 30%;
}

.ui-keyboard-spacer {
  display: inline-block;
}

/********************************
END KEYBOARD STYLES
********************************/

.profile-form-container,
.profile-view-container,
.role-section-container {
  padding-top: $header-height + 32px;
  flex: 1;

  &__button {
    height: 35px;
  }
}

.larger-text {
  color: #000000;
  font-size: $font-size-h2;
}

.situation {
  color: #000000;
  font-size: 22px;
  font-weight: bold;
  line-height: 27px;
}

.prompt {
  color: #000000;
  font-size: 22px;
  font-weight: bold;
  line-height: 27px;
  //padding-left: 46px;
}

.listening-instruction {
  color: #585858;
  font-size: 14px;
  line-height: 24px;
}

.hidden-instruction-prompt {
  display: none;
}

.list-group-item {
  label {
    color: #000000;
  }
}

.grid {
  display: flex;
  flex-direction: column;
  margin: 52px 42px 46px 42px;

  .grid-row {
    display: flex;
    flex-direction: row;

    .grid-col-height {
      height: 20px;
    }

    .grid-col {
      margin-left: 20px;
    }

    .grid-offset-1 {
      margin-left: 80px;
    }

    .grid-offset-2 {
      margin-left: 160px;
    }

    .grid-offset-3 {
      margin-left: 240px;
    }

    .grid-offset-4 {
      margin-left: 320px;
    }

    .grid-offset-5 {
      margin-left: 400px;
    }

    .grid-offset-6 {
      margin-left: 480px;
    }

    .grid-offset-7 {
      margin-left: 560px;
    }

    .grid-offset-8 {
      margin-left: 640px;
    }

    .grid-offset-9 {
      margin-left: 720px;
    }

    .grid-offset-10 {
      margin-left: 800px;
    }

    .grid-offset-11 {
      margin-left: 880px;
    }

    .grid-col-1 {
      width: 60px;
    }

    .grid-col-2 {
      width: 140px;
    }

    .grid-col-3 {
      width: 220px;
    }

    .grid-col-4 {
      width: 300px;
    }

    .grid-col-5 {
      width: 380px;
    }

    .grid-col-6 {
      width: 460px;
    }

    .grid-col-7 {
      width: 540px;
    }

    .grid-col-8 {
      width: 620px;
    }

    .grid-col-9 {
      width: 700px;
    }

    .grid-col-10 {
      width: 780px;
    }

    .grid-col-11 {
      width: 860px;
    }

    .grid-col-12 {
      width: 940px;
    }
  }
}

.advance-admin-styles {
  padding: 8px;
  text-align: center;
}


/********************************
Generic Styles for making layout easier
********************************/
.flex-row {
    display: flex;
    flex-flow: row;
}

.flex-col {
    display: flex;
    flex-flow: column;
}

.no-wrap { flex-wrap: nowrap; }
.items-center { align-items: center; }
.items-stretch { align-content: stretch; }
.items-start { align-items: flex-start; }
.items-end { align-items: flex-end; }
.items-between { align-content: space-between; }

.justify-center { justify-content: center }
.justify-start { justify-content: flex-start;}
.justify-end { justify-content: flex-end; }
.justify-between { justify-content: space-between; }

.text-center { text-align: center; }

.fill { width: 100%; height: 100%; flex: 1 1 100%; }

/********************************
Margin & Padding styles: This madness generates margin & padding styles for us.
The class format will be of the following:
.{m|p}{l|r|t|b|h|v}{0-20}
ml2
mr2
pa2
********************************/
$marginPadding_types: (m: "margin", p: "padding");
$marginPadding_sides: (
    l: (left),
    r: (right),
    t: (top),
    b: (bottom),
    h: (left, right),
    v: (top, bottom),
    a: (top, bottom, left, right)
);
$marginPadding_sizes: (0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20);

@each $type, $typeVal in $marginPadding_types { // iterate over margin or padding
    @each $sideKey, $side in $marginPadding_sides { // iterate over our sideGroups
        @each $size in $marginPadding_sizes { // iterate over all size increments
            .#{$type}#{$sideKey}#{$size} { 
                @each $prop in $side { // assign all properties in that sideGroup
                    #{$typeVal}-#{$prop}: calc(#{$margin-xs-size} * #{$size}) !important;
                }
            }
        }
    }
}


.eWpLko {
  position: unset !important;
}