.special-characters {
  display: flex;
  align-items: center;
  margin: 6px 0;

  &__line {
    height: 1px;
    background-color: rgba(205,205,205,0.5);
  }

  &__icon {
    font-size: 20px;
    line-height: 16px;
    color: #CDCDCD;
    margin-right: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  &__character {
    color: #767676;
    font-size: 14px;
    line-height: 16px;
    height: 32px;
    width: 32px;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: rgba(205,205,205,0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
      background-color: #E2E2E2;
    }

    &:active {
      background-color: #CDCDCD;
    }

    &--last {
      border-width: 0;
    }
  }
}
