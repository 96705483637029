.speaking {
  &__prompt {
    color: #000000;
    font-size: 22px;
    font-weight: bold;
    line-height: 27px;
    padding-bottom: 20px;
  }

  &__container {
    border: 1px solid #D6D6D6;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(192, 192, 192, 0.5);
    color: #585858;
    font-size: 14px;
    line-height: 24px;
    padding: 23px 18px 23px 19px;
  }

  &__remember {
    padding-top: 20px;
    color: #767676;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
  }

  &__remember-point {
    color: #767676;
    font-size: 18px;
    font-weight: bold;
    line-height: 32px;
    display: flex;
    align-items: center;

    &__icon {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: #CDCDCD;

      &__check {
        color: #FFFFFF;
        font-size: 18px;
        position: relative;
        bottom: 4px;
        left: 1px;
      }
    }
  }
}
