@import "../../../../styles/variables";

.StopListeningButton {
  margin-right: 17px;
  width: $voicerecorder-stop-btn-width;
  height: $voicerecorder-btn-height;
  border-radius: $voicerecorder-btn-border-radius;
  font-weight: $voicerecorder-btn-font-weight;
  font-size: $voicerecorder-btn-font-size;
  line-height: $voicerecorder-btn-line-height;
  background-color: $voicerecorder-bg-blue;
  color: $voicerecorder-txt-white;
  border: 0px;
  &__icon {
    display: inline-block;
    height: 16px;
    width: 16px !important;
    position: relative;
    top: 3px;
    left: -26px;
    background: url($voicerecorder-stop-icon) round;
  }

  &__text {
    position: relative;
    top: 0px;
    left: 8px;
  }

  &:hover {
    cursor: pointer;

    background-color: darken($voicerecorder-bg-blue, 10%);
  }
}
