@import "../../../styles/variables";

.VoiceVolumeVisualization {
  text-align: left;
  display: flex;
  align-items: center;

  &__meta {
    text-align: right;
    margin-right: 8px;
    line-height: 1.4 !important;
    font-size: 14px;

    &--mic {
      color: #585858;
      position: relative;
    }

    &--status {
      font-weight: bold;
      color: #000000;
    }

    &--helplink {
      font-weight: normal !important;
      color: $voicerecorder-txt-blue;
      &:hover {
        cursor: pointer;
        color: darken($voicerecorder-txt-blue, 10%);
      }
    }
  }
}
