@import "../../styles/variables.scss";

.item-menu {
  background-color: #FAFAFA;
  position: fixed;
  flex: 1;
  top: 70px;
  bottom: 75px;
  overflow-y: auto;

  &__disabled {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    height: 100%;
    width: 100%;
  }

  &__container {
    background-color: #FFFFFF;
    padding: 16px 14px 14px 14px;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-color: #CDCDCD;
    color: #3B3832;
    width: 224px;
    flex: 1;
    top: 70px;
    bottom: 75px;

    &--columns {
      display: flex;
      justify-content: space-between;
    }

    .selected-button {
      background-color: #D8D8D8;
      width: 8rem;
    }

    .correct-button {
      background-color: green;
      width: 8rem;
    }

    .incorrect-button {
      background-color: red;
      width: 8rem;
    }
  }

  &__button {
    border: 1px solid #CDCDCD;
    border-radius: 1px;
    background-color: #FFFFFF;
    padding: 6px;
    margin-bottom: 6px;
    color: #3B3832;
    font-size: 12px;
    font-family: "Helvetica Neue", sans-serif;
    line-height: 12px;

    &__text {
      display: inline-block;
    }

    &--active {
      background-color: #D8D8D8;

      .circle-xs {
        border: 1px solid #FFFFFF;
        margin-left: 2px;
        position: relative;
        top: 1px;

        &.no {
          border: 1px solid #FFFFFF !important;
          background-color: inherit;
        }
      }
    }

    .circle-xs {
      border: 1px solid #FFFFFF;
      margin-left: 2px;
      position: relative;
      top: 1px;

      &.no {
        border: 1px solid #CDCDCD;
        background-color: inherit;
      }
    }
  }
}
